.Section {
    width: 100%;
    display: flex;
    justify-content: center;
    
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: whitesmoke;
    color: black;
    
  }
  
  .react-carousel1{
    padding: 10px;
    width: 90%;
    border: none;
    border-radius: 50px;
    position: relative;
    background: linear-gradient(to right, red, orange);
    
  }

  .sectioncontainer {
    display: flex;
    gap: 30px; 
    flex-direction: row;
    padding: 40px;
    justify-content: center;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden; 
    
  }
  
  .sectionitems {
    max-width: 240px;
    height: auto;
    flex: 0 0 auto;
    
    
  }

  .sectionitems .pic{
    
    border-radius: 50%;
    overflow: hidden;
  }
  
  .slide1 {
    
    

  }

  .slide1 h3{
    color: white;
    

  }

.slide1 .text-animation {
    animation: fadeIn 3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  
@media screen and (max-width: 650px) {

  .sectioncontainer{
    gap: 10px;
    padding: 20px;
  }

  .sectionitems {
    max-width: 180px;
    height: auto;
    flex: 0 0 auto;
  
    
  }

  .sectioncontainer {
    display: flex;
    gap: 10px; 
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    white-space: nowrap; /* Prevent wrapping of images */
    overflow: hidden; /* Hide the scrollbar */
  }
  
}