/* Form.css */

.form1 {
    background-color: white;
    color: black;
    text-align: left;
    position: relative;
    height: auto;
    width: 450px;
    padding: 30px;
    z-index: 2;
    box-shadow: 0px -18px 20px -15px rgba(0, 0, 0, 0.3);
}

.form1 .input1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.form1 input[type="text"],
.form1 input[type="tel"],
.form1 input[type="date"],
.form1 input[type="number"],
.form1 input[type="email"],
.form1 input[type="time"],
.form1 select {
    background-color: whitesmoke;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    color: gray;
    border: none;
}

.form1 h3 {
    margin-bottom: 5px;
}

.buttonx {
    padding: 14px;
    background: linear-gradient(to right, red, orange);
    border: none;
    width: 100%;
    border-radius: 50px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin: 10px;
}

.alert {
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-size: 16px;
    
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.alert-error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.alert .closebtn {
    margin-left: 15px;
    color: inherit;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.alert .closebtn:hover {
    color: black;
}

@media (max-width: 600px) {
    .form1 {
        width: 96%;
        padding: 10px;
    }
}
