.App {
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
}

.gradient-text {
  background: linear-gradient(to right, red, orange);
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
}


.Strip {
  width: 100%;
  position: relative;
  z-index: 2;
  height: 40px;
  background-color: white;
  color: black;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  overflow: hidden;
  font-size: 14px;
  border-radius: 2px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2)!important;
  /* Adding box shadow */
}

.Strip .icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
}

@media screen and (max-width: 650px) {
  .Strip .icons {
    display: none;
  }

  .Strip .tag {
    display: none;
  }

}

a {
  text-decoration: none;
  color: black;
}

.active-link {
  color: red;
}

.Navbar {
  width: 100% !important;
  height: 150px;
  background-color: white;
  color: black;
  font-family: sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

}

.NavbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
}

.Navbar .NavbarContainer .links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  justify-content: center;
  color: black !important;
  gap: 20px;
  font-size: 19px !important;
}

.NavbarContainer .logo {
  width: 130px;
  height: auto;
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  background-color: whitesmoke;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: 0.3s;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 22px;

  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  color: black;
  list-style: none;
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin: 40px;
}

.app__navbar-smallscreen_links li {

  margin: 10px;
  color: black;
  font-size: 20px;
  text-align: left;
}



.order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.order .phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.order .phone p {
  font-size: 20px;
  color: goldenrod;
}


.button {
  padding: 16px;

  color: white;
  background: linear-gradient(to right, red, orange);
  border-radius: 10px;
  border: none;
  font-weight: bold;
  transition: 0.3s ease-in;
  font-size: 20px;

}

.button:hover {
  
transform: scale(1.06,1.06);
}



@media screen and (max-width: 650px) {

  .Navbar .NavbarContainer .links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
    background-color: white !important;
    align-items: center;
    justify-content: center;
  }


}

.order .button button a {
  font-size: 20px;
  font-weight: bold;
  color: orangered;
}

.order .button button:hover a {
  color: black;
}

.order .button button:hover {
  color: black;
  border: 3px solid white;

}


.intro {

  background: linear-gradient(rgba(0, 0, 0, 0.271), rgba(0, 0, 0, 0.371)), url("../src/assets/img/limo.jpg");
  margin-top: 0;
  padding: 50px 0 50px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

}

.introContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: white;
  overflow: hidden;

  padding: 40px;
}

.introContainer .text {
  text-align: start;
  width: 500px;
  line-height: 20px;
  padding: 30px;
}

.introContainer .text p {
  font-weight: 500;
}

.introContainer .text h1 {
  font-size: 40px;
  color: white;

}

.introContainer .text button {
  padding: 15px;
  border-radius: 30px;
  border: none;
  color: white;
  background: linear-gradient(to right, red, orange);

  font-weight: bold;
  font-size: 14px;
}

.introContainer .calender {
  background-color: transparent;
  
}

.Banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
  color: black;
}

.BannerContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.BannerContainer p {
  color: black;
}

.BannerContainer .PlansContainer {
  display: flex;
  flex-direction: row !important;
  align-items: top;
  width: 80%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  height: auto;


}



.PlansContainer .PlansItems {
  width: 400px;
  height: auto;
  padding: 20px;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgb(228, 226, 226);
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.PlansItems h2{
  color: black;
}


.PlansContainer .PlansItems .icon {}





.Contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  overflow: hidden;
  background-color: black;
  color: white;
}


.ContactContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.ContactItems {}





.Services {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.Services .Moving {

  background: linear-gradient(to right, red, orange);
  color: white;
  padding: 30px;
  text-align: start;
  width: 75%;
  font-weight: bold;

}

.Services .Moving h1 {
  color: white;
}



.footer-container {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  overflow: hidden;
}

.footer-container h2{
  color: white;
}


.footer-container .footerBox {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
  text-align: start;
  padding: 20px;
}

.footer-container .footerBox .about,
.quicklinks,
.numa {
  width: 300px;
}



.Testimon {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: whitesmoke;
  color: black;


}

.TestimonContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.TestimonContainer .elements {
  width: 300px;
  height: 300px;
  background-color: transparent;
  padding: 10px;
  border: 1px solid white;
  background-color: white;
  transition: 0.5s ease;
}

.Testimon .clients h1 {
  color: black;
}

.TestimonContainer .elements h3 {
  color: black;
}

.TestimonContainer .elements:hover {
  transform: scale(1.03, 1.03);
}




.Testimon .twoStrip {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: linear-gradient(to right, red, orange);
  color: white;
}

.Testimon .twoStrip .special {
  width: 500px;
  height: auto;
  text-align: start;
  padding: 10px;

}

.Testimon .twoStrip .special h2 {
  color: black;

}

.Testimon .twoStrip p {
  font-weight: 600;
}

.Testimon .clients h1 {
  font-size: 35px;

}

.Success{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
  color: black;
}

.Success .SuccessContainer{

  padding: 20px;
  margin: 40px;
  background-color: whitesmoke;

}

.buttonx5 {
  padding: 14px;
  background: linear-gradient(to right, red, orange);
  border: none;
  width: 100%;
  border-radius: 50px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}




@media screen and (max-width: 760px) {

  .Strip {
    font-size: 10px;
  }

  .order {
    display: none;
  }

  .Navbar {
    margin: 0px;
  }

  .Navbar .NavbarContainer .logo {
    width: 100px;
    height: auto;
  }

  .introContainer {
    height: auto;
    flex-direction: column;
    padding: 20px;
  }

  .introContainer .text {

    width: 100%;

    padding: 30px;
  }

  .introContainer .text h1 {
    line-height: 50px;
  }

  .BannerContainer .PlansContainer {

    flex-direction: column !important;
    width: 300px;
    align-items: center;

  }

  .Contact .oneStrip {

    flex-direction: column;
    height: auto;
    gap: 4px;
    margin-bottom: 10px;
  }

  .Contact .oneStrip .special {
    width: 300px;
    text-align: center;
  }

  .Contact .oneStrip .num {
    height: auto;
    padding-bottom: 5px;
  }

  .Services {

    flex-direction: column;
    gap: 0px;
    overflow: hidden;
  }

  .Plan .ServiceContainer {

    flex-direction: column;
    margin-bottom: 40px;
    gap: 10px;

  }

  .TestimonContainer {

    flex-direction: column;
    gap: 10px;

  }

  .TestimonContainer .elements {
    width: 300px;
    height: auto;
    text-align: center;

  }

  .Testimon .twoStrip {
    flex-direction: column;
    gap: 10px;
  }

  .Testimon .twoStrip .special {
    width: 300px;
    height: auto;
    text-align: center;

  }

  .ServiceContainer .ServiceItems {
    width: 300px;
    height: auto;

  }

  .footer-container {
    height: auto;
  }

  .footer-container .footerBox {

    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    text-align: start;
    padding: 20px;
  }

  .footer-container .footerBox .about,
  .quicklinks,
  .numa {
    width: 300px;
  }

  .Plan .ServiceContainer {
    padding: 20px;
  }

  .NavbarContainer .logo {
    width: 170px;
    height: 75px;
  }

  .Services .Moving {

    padding: 10px;
    height: auto;
    font-weight: normal;

    width: 99%;

  }

  .PlansContainer .PlansItems {
    width: 280px;
    height: auto;
    padding: 10px;

  }


}