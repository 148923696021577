.faq-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    overflow: hidden;
  }

  .faq-container h1{
    font-size: 35px;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  .faq-item {
    margin: 10px 0;
  }
  
  .faq-question {
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .faq-question:hover {
    background-color: #e0e0e0;
  }
  
  .faq-answer {
    height: auto;
    overflow: hidden;
    transition: max-height 0.3s;
    text-align: left;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f1f1f1;
  }
  
  .faq-item.open .faq-answer {
    max-height: 1000px;
    transition: max-height 0.5s;
  }
  
  @media screen and (max-width: 650px) {
    
      .faq-container {
        padding: 0px;
        max-width: none;

        width: 99%;
      }
      .faq-list{
        padding: 10px;
      }
      
   
}